import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import { Link } from 'gatsby'
import { useState, useEffect } from "react"
import { isLoggedIn, logout } from '../services/auth'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from "react-helmet"

import Amplify, { Storage } from 'aws-amplify'
import awsmobile from '../aws-exports'
Amplify.configure(awsmobile)

class IndexPage extends Component {
  state = {
    smallScreen: false,
    modal: { name: "" },
  }
  
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  openModal(e) {
    this.setState({ modal: e })
    document.getElementById("modal").style.display = "block"
  }

  closeModal() {
    document.getElementById("modal").style.display = "none"
  }
  render() {
    let indexData = this.props.data.allIndexJson.nodes[0]
    let homeItems = []
    let i = 0    
    indexData.home_items.forEach(item => {
      homeItems.push(
        <div key="uniqueId1"> 
        <div class="flex-1 flex flex-col p-3"><div class="bg-gray px-6 py-10 border-2 rounded-lg shadow-xl">
        <div><h2 id="tier-payg" class="text-center text-2xl leading-8 font-medium text-gray-900">{item.name}</h2>
        <div class="mt-3 flex items-center justify-center"><span class="px-2 flex items-start text-4xl xl:text-5xl leading-none tracking-tight text-gray-900">
        <span class="mt-1 mr-1 text-2xl xl:text-3xl font-medium">EUR</span>
        <span class="font-extrabold">{item.dataitemprice}</span>
        </span>
        <span class="text-xl xl:text-2xl leading-7 font-medium text-gray-500"></span>
        </div></div></div><div class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10 border-2 rounded-lg shadow-xl"><ul><li class="flex items-start"><div class="flex-shrink-0"><svg class="h-6 w-6 text-green-500"><use xlinkHref=""></use></svg></div><p class="ml-3 text-base leading-6 font-medium text-gray-500">Toegang tot de 10 audiolessen</p></li><li class="mt-4 flex items-start"><div class="flex-shrink-0"><svg class="h-6 w-6 text-green-500"><use xlinkHref=""></use></svg></div><p class="ml-3 text-base leading-6 font-medium text-gray-500">Registratie en login worden per email toegestuurd.</p></li></ul><div class="mt-8">
<button class="snipcart-add-item block w-full text-center rounded-lg border border-transparent bg-green-500 hover:bg-green-700 text-white px-6 py-3 text-base leading-6 font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
               data-item-id={item.dataitemid}
             data-item-price={item.dataitemprice}
                data-item-url={item.dataitemurl}
               data-item-name={item.dataitemname}
               data-item-description={item.dataitemdescription}
                data-item-image={item.dataitemimage}
               >
                Bestel
              </button>
</div></div></div>
        </div>)
      i++
    })
    return (
      <Layout page={"home"}>
      	<div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.data.site.siteMetadata.title}</title>
          <link rel="canonical" href={this.props.data.site.siteMetadata.siteUrl} />
          <meta name='description' content={this.props.data.site.siteMetadata.description} />
        </Helmet>
      </div>
        <h1 className="title">{indexData.title}</h1>
        <div className="home-main">
        <div className="homepage-grid-container">
        <div className="homepage-grid-item-left">	
          <div className="text">{indexData.text}</div>
        </div>
        <div className="homepage-grid-item-right">
        <img src={indexData.image} alt="Chris Lenaerts"></img>  
        </div>
        
        </div>	
        
        <h2 class="subtitle">
        And last but not least:
		Graag wil ik al mijn patiënten van de voorbije jaren hartelijk danken voor het vertrouwen, de moed en de bijzonder fijne samenwerking!!
        </h2>
        
        
		
			<div className="divider"></div>
		
          	{isLoggedIn() ? (	
					<div class="flex justify-center space-x-5">			
						<div><Link to="/app/home"><button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Lessenreeks</button></Link></div>
						<div><Link to="/app/profile"><button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Mijn Profiel</button></Link></div>			
					</div>
			  ) : (
			  
			  
			  <div class="flex justify-center space-x-5">
				  	<Link to="/about"><button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Meer weten</button></Link>
	          </div>
			  )}
		 
			  
          <div className="divider"></div>
        
          <h2 className="subtitle">{indexData.subtitle}</h2>
        
          <div
            className={
              this.state.smallScreen
                ? "grid-container-small"
                : "buy-grid-container"
            }
          >
         
            {homeItems}
     
          </div>
        </div>
        <div id="modal" className="modal" onClick={this.closeModal}>
          <div
            className={
              this.state.smallScreen ? "modal-content-small" : "modal-content"
            }
          >
            <span className="modal-close">&times;</span>
            <div className="modal-grid-container">
              	<div className="modal-grid-item-left">
	                <span className="modal-title">{this.state.modal.name}</span>
	                <p className="modal-text">{this.state.modal.description}</p>
	                <p className="modal-text">
	                </p>
	                <button
			    	class="snipcart-add-item bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
			    	data-item-name={this.state.modal.dataitemname}
			    	data-item-id={this.state.modal.dataitemid}
			    	data-item-price={this.state.modal.dataitemprice}
			    	data-item-url={this.state.modal.dataitemurl}
			    	data-item-description={this.state.modal.dataitemdescription}
					>
			    	Inschrijven
					</button>
				</div>
				<div className="modal-grid-item-right">
                <img
                  src={this.state.modal.image}
                  alt={this.state.modal.name}
                  className="modal-image"
                ></img>
				</div>            
			</div>
		</div>
        

		
        
        
        </div>
  

               <div className="divider"></div>
					{isLoggedIn() ? (	
					<div class="flex justify-center space-x-5">			
						<div><Link to="/app/home"><button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Lessenreeks</button></Link></div>
						<div><Link to="/app/profile"><button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Mijn Profiel</button></Link></div>			
					</div>
					) : (
					<div class="flex justify-center space-x-5">
						<p>Nadat u de bestelling hebt geplaatst kan u met behulp van de registratieknop een profiel aanmaken.</p>
							
					</div>
				  	)}
          <div className="divider"></div>
          <div class="flex justify-center space-x-5">
						
						<p><div><Link to="/app/signup"><button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Registreren</button></Link></div></p>		
					</div>
                    
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query {
    allIndexJson {
      nodes {
        title
        description
        text
        subtitle
        image
        home_items {
          name
          description
          completed
          image
          dataitemid
          dataitemprice
          dataitemurl
          dataitemname
          dataitemdescription
          dataitemimage
        }
      }
    }
    site {
    siteMetadata {
      title
      description
      siteUrl
    }
  }
  }
`
/*
export const sitequery = graphql`
query {
  site {
    siteMetadata {
      title
      description
    }
  }
}
*/
